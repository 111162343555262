import axios from "axios";
import React, { useEffect, useState } from "react";
import { mainUrl } from "../constants";

function VolunteerSection() {
  const [data, setData] = useState([]);
  const [nameSearchQuery, setNameSearchQuery] = useState("");
  const [bloodGroupSearchQuery, setBloodGroupSearchQuery] = useState("");
  const [addressSearchQuery, setAddressSearchQuery] = useState("");
  const [municipalitiesVdcSearchQuery, setMunicipalitiesVdcSearchQuery] =
    useState("");
  const [wardNumberSearchQuery, setWardNumberSearchQuery] = useState("");

  const filteredData = data.filter((item) => {
    const name = item.first_name + " " + item.last_name;
    const nameMatch = name
      .toLowerCase()
      .includes(nameSearchQuery.toLowerCase());
    const bloodGroupMatch = item.bloodGroup
      .toLowerCase()
      .includes(bloodGroupSearchQuery.toLowerCase());

    const addressMatch = [
      // item.current_address.tole.toLowerCase(),
      item.current_address.toLowerCase(),
      // item.current_address.ward,
      // item.current_address.district.toLowerCase(),
    ].includes(addressSearchQuery.toLowerCase());
    // const municipalitiesVdcMatch = item.current_address.municipality
    const municipalitiesVdcMatch = item.current_address
      .toLowerCase()
      .includes(municipalitiesVdcSearchQuery.toLowerCase());
    const wardNumberMatch =
      // item.current_address.ward === wardNumberSearchQuery ? true : false;
      item.current_address === wardNumberSearchQuery ? true : false;

    // Combine the search queries using AND (&&) to filter the data
    return (
      nameMatch &&
      bloodGroupMatch &&
      // wardNumberMatch &&
      // addressMatch &&
      municipalitiesVdcMatch
    );
  });

  useEffect(() => {
    axios
      .get(`${mainUrl}/store/people/volunteers/`)
      .then((response) => {
        // console.log(response);
        setData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="px-4 py-4 md:px-8 mt-2">
      <h2 className="text-3xl font-bold mt-3 mb-4 text-center">
        Brave Youth For Nation
      </h2>
      <p className="text-xl text-center mb-4">
        "Thankyou ! Young Generation for your kind support and love for the
        nation"
      </p>
      <div className="flex flex-col md:flex-row justify-center md:space-x-4 mb-2">
        {/* Search by Name */}
        <input
          type="text"
          placeholder="Search by Name"
          value={nameSearchQuery}
          onChange={(e) => setNameSearchQuery(e.target.value)}
          className="px-2 py-1 border rounded w-full md:w-auto mb-2 md:mb-0"
        />

        {/* Search by Blood Group */}
        <input
          type="text"
          placeholder="Search by Blood Group"
          value={bloodGroupSearchQuery}
          onChange={(e) => setBloodGroupSearchQuery(e.target.value)}
          className="px-2 py-1 border rounded w-full md:w-auto mb-2 md:mb-0"
        />

        {/* Search by Temporary Address */}
        <input
          type="text"
          placeholder="Search by Temporary Address"
          value={addressSearchQuery}
          onChange={(e) => setAddressSearchQuery(e.target.value)}
          className="px-2 py-1 border rounded w-full md:w-auto mb-2 md:mb-0"
        />

        {/* Search by Municipalities/Vdc */}
        <input
          type="text"
          placeholder="Search by Municipalities/Vdc"
          value={municipalitiesVdcSearchQuery}
          onChange={(e) => setMunicipalitiesVdcSearchQuery(e.target.value)}
          className="px-2 py-1 border rounded w-full md:w-auto mb-2 md:mb-0"
        />

        {/* Search by Ward Number */}
        <input
          type="text"
          placeholder="Search by Ward Number"
          value={wardNumberSearchQuery}
          onChange={(e) => setWardNumberSearchQuery(e.target.value)}
          className="px-2 py-1 border rounded w-full md:w-auto"
        />
      </div>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse text-center">
          <thead>
            <tr className="bg-blue-500 text-white">
              <th className="py-2">S.N</th>
              <th className="py-2">Name</th>
              <th className="py-2">Contact</th>
              <th className="py-2">Age</th>
              <th className="py-2">Temporary Address</th>
              <th className="py-2">Workspace</th>
              <th className="py-2">Profession</th>
              <th className="py-2">Blood Group</th>
              <th className="py-2">Municipalities/Vdc</th>
              <th className="py-2">Ward Number</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item, index) => (
              <tr key={index} className="border">
                <td className="py-2">{index + 1}</td>
                <td className="py-2">
                  {item.first_name + " " + item.last_name}
                </td>
                <td className="py-2">{item.contact}</td>
                <td className="py-2">{item.age}</td>
                {/* <td className="py-2">{`${item.current_address.tole}-${item.current_address.ward},${item.current_address.district} (${item.current_address.local_name})`}</td> */}
                <td className="py-2">{`${item.current_address})`}</td>
                <td className="py-2">{`${item.current_address}`}</td>
                <td className="py-2">{item.profession}</td>
                <td className="py-2">{item.bloodGroup}</td>
                {/* <td className="py-2">{item.current_address.municipality}</td> */}
                <td className="py-2">{item.permanent_address.municipality}</td>
                {/* <td className="py-2">{item.current_address.ward}</td> */}
                <td className="py-2">{item.permanent_address.ward}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default VolunteerSection;
