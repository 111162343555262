import React from "react";

const PrivacyAndPolicy = () => {
  return (
    <>
      <h1 className="text-center mt-4 font-extrabold">Privacy and Policy</h1>
      <p className="text-center">Updating very Soon...................... </p>
    </>
  );
};

export default PrivacyAndPolicy;
