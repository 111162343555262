import React, { useState } from "react";
import { FaBars, FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const NavBar = () => {
  const { is_staff } = useSelector((store) => store.user);
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    // <div className="w-full min-h-[50px] flex justify-between items-center absolute z-10 text-white bg-gray-800 ">
    <div className="w-full min-h-[50px] flex justify-between items-center z-90 text-white bg-gray-800 ">
      {/* Desktop Menu */}
      <ul className="hidden sm:flex px-4">
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/about">About Us</Link>
        </li>
        {is_staff && (
          <li>
            <Link to="/blooddoner">Blood Donors</Link>
          </li>
        )}
        <li>
          <Link to="/blogs">Blogs</Link>
        </li>
        <li>
          <Link to="/gallery">Gallery</Link>
        </li>
        <li>
          <Link to="/contact">Contact Us</Link>
        </li>
      </ul>

      {/* Social Media Icons */}
      <div className="flex justify-between">
        <Link to="" target="_blank">
          {" "}
          <FaFacebookF className="mx-4" />
        </Link>

        <Link to="" target="_blank">
          {" "}
          <FaTwitter className="mx-4" />
        </Link>

        <Link to="" target="_blank">
          {" "}
          <FaInstagram className="mx-4" />
        </Link>
      </div>

      {/* Hamburger icons here */}
      <div onClick={handleNav} className="sm:hidden z-100000">
        <FaBars size={20} className="mr-4 cursor-pointer" />
      </div>

      {/* Mobile Menu */}
      <div
        onClick={handleNav}
        className={`
        overflow-y-hidden md:hidden ease-in duration-300 fixed text-gray-300 top-0 w-full h-screen bg-slate-950 px-4 py-7 flex-column z-40
        ${nav ? "left-0" : "left-[-100%]"}
        `}
      >
        <ul className="w-full h-full text-center">
          <li className="text-2xl py-8">
            <Link to="/">Home</Link>
          </li>
          <li className="text-2xl py-8">
            <Link to="/about">About Us</Link>
          </li>
          {is_staff && (
            <li className="text-2xl py-8">
              <Link to="/blooddoner" className="text-green-600 hover:text-green-800">Blood Donors</Link>
            </li>
          )}
          <li className="text-2xl py-8">
            <Link to="/blogs">Blogs</Link>
          </li>

          <li className="text-2xl py-8">
            <Link to="/gallery">Gallery</Link>
          </li>
          <li className="text-2xl py-8">
            <Link to="/contact">Contact Us</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavBar;
