import React, { useEffect, useRef, useState } from "react";
import { AiFillPhone } from "react-icons/ai";
// import { FaSignInAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import logo from "/src/assets/images/logolifelineachham.png";
import logo from "../../assets/images/LifelineLogo.png";

import { FiMail } from "react-icons/fi";
const TopBar = () => {
  const { first_name } = useSelector((store) => store.user);
  const navigate = useNavigate();
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef();

  const handleButtonClick = () => {
    setShowOptions((prevShowOptions) => !prevShowOptions);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowOptions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex justify-between items-center px-4 py-2">
      <div className="flex items-center">
        {/* <Link to="/">
          <h1 className="text-xl font-bold text-gray-600">LifeLine Achham</h1>
        </Link> */}
        <Link to="/">
          {/* <img
            className=""
            src="https://scontent.xx.fbcdn.net/v/t1.15752-9/394013143_1402892150263947_4017802105486098215_n.png?_nc_cat=102&ccb=1-7&_nc_sid=510075&_nc_ohc=STWATeEFcZkAX90VNUP&_nc_ad=z-m&_nc_cid=0&_nc_ht=scontent.xx&oh=03_AdR0FGBIHXuf7MF2UUHpVX4S3di3crPoPtgh3eV2F8f7Lw&oe=655CF888"
            alt=""
          /> */}
          <img src={logo} alt="Logo of lifeline Achham" />
        </Link>
      </div>
      {/* ========================================================= */}
      <div className="flex">
        <div className="hidden md:flex items-center px-6">
          <FiMail size={20} className="mr-2 text-[var(--primary-dark)]" />
          <p className="text-sm text-gray-700"> support@lifelineachham.org</p>
        </div>
        {/* ------------------------------------------------------------------------------ */}
        <div className="hidden md:flex items-center px-6">
          <AiFillPhone size={20} className="mr-2 text-[var(--primary-dark)]" />
          <p className="text-sm text-gray-700"> 9810228669</p>
        </div>
        {/* ----------------------------------------------------------------------------- */}
        {/* if need hidden to hide in the mobile nav bar */}
        {/* <div className=" md:flex">
          <button className="rounded-lg"> Apply Volunteer</button>
        </div> */}

        <div className="md:flex">
          {/* If you need to hide in the mobile nav bar */}
          <div className="relative" ref={dropdownRef}>
            <button className="rounded-lg" onClick={handleButtonClick}>
              Apply Now
            </button>

            {showOptions && (
              <div className="absolute top-full left-0 bg-white border border-gray-300 rounded-lg shadow-md p-2 z-50">
                <Link
                  to="/apply-achhamblooddonor"
                  className="block my-2  hover:bg-gray-200  p-2"
                  onClick={() => setShowOptions(false)}
                >
                  Apply as Blood Donor
                </Link>

                <Link
                  to="/apply-volunteer"
                  className="block my-2  hover:bg-gray-200  p-2"
                  onClick={() => setShowOptions(false)}
                >
                  Apply as Volunteer
                </Link>
              </div>
            )}
          </div>
          <div>
            {first_name && (
              <h1 className="mt- px-2 text-center font-serif uppercase ">
                Hi
                <br /> {first_name}
              </h1>
            )}
          </div>
        </div>

        {/* <div className="hidden  md:flex"> */}

        {showOptions && (
          <div className=" md:flex  ">
            {/* <Link to="/loginsignup"> */}
            <Link to="/login" onClick={() => setShowOptions(false)}>
              <button className="rounded-lg border-none bg-none  text-blue-600 ">
                {/* <FaSignInAlt /> */}
                Login/Sign Up
              </button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopBar;
