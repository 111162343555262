import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <div className="w-full bg-zinc-100 py- md:py-4 lg:py-10 flex flex-col justify-between">
      <div className="grid md:grid-cols-2 max-w-[1240px] mx-auto md:px-4 lg:px-8">
        <div className="flex flex-col justify-center md:items-start w-full px-2 py-4 md:py-8">
          <p className="text-l md:text-1xl lg:text-1xl mt-2 italic text-blue-600">
            Pulsating with Kindness
          </p>
          <h1 className="text-2xl md:text-xl lg:text-3xl  font-bold text-blue-600">
            Lifeline Achham
          </h1>
          <p className="text-base md:text-lg lg:text-l mt-2 md:mt-6">
            <strong className="text-black-800">Lifeline Achham </strong> is a web application that contains information
            about active blood donors in Achham. These donors are prepared to
            donate their blood to assist individuals in need during emergencies.
            For now, Bayalpata Hospital and Achham District Hospital will be the primary user of this web
            application, while the people of Achham will serve as the blood donors.
            This project aims to foster unity among the youth and engage all
            responsible individuals in Achham to work collaboratively for its
            improvement and the well-being of its residents.
          </p>
          <Link to="/apply-achhamblooddonor">
            <button className="mt-6 px-6 py-3 rounded-lg bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300">
              Donate Now
            </button>
          </Link>
        </div>
        <div className="mt-6 md:mt-0  ">
          <img
            className="w-full h-full object-cover rounded-lg mt-0 mb-4 "
            src="https://media-cldnry.s-nbcnews.com/image/upload/rockcms/2023-01/230127-blood-donation-al-0933-067298.jpg"
            alt="images"
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;

// import FoundationImage from "./assets/FoundationImages.jpg";
// const Hero = () => {
//   return (
//     <div className="w-full h-screen bg-zinc-100 flex flex-col justify-between">
//       <div className="grid md:grid-cols-2 max-w-[1240px] m-auto">
//         <div className="flex flex-col justify-center md:items-start w-full px-2 py-8 ">
//           <p className="text-2xl"> We For Change </p>
//           <h1 className="py-3 text-5xl md:text-4xl font-bold">
//             MAHENDRA KUNWAR FOUNDATION
//           </h1>
//           <p className="text-1xl">
//             Kunwar Foundation is a non-profit organization dedicated to
//             improving the lives of underprivileged individuals. Through various
//             programs, we provide essential resources and support to uplift the
//             poor and marginalized communities. Our initiatives focus on
//             education, healthcare, livelihood development, and empowering
//             individuals to break the cycle of poverty. By addressing the needs
//             of the vulnerable, we strive to create positive and sustainable
//             change, fostering a better future for all.{" "}
//           </p>
//           <button> Get Started</button>
//         </div>
//         <div>
//           <img src={FoundationImage.jpg} alt="images " />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Hero;
