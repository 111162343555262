import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { mainUrl } from "../../constants";
import BlogCard from "./BlogCard";

const BlogSection = () => {
  const [blogData, setBlogData] = useState([]);

  // State to store the selected section filter (default: "All")
  const [selectedFilter, setSelectedFilter] = useState("All");

  // State to store the search query
  const [searchQuery, setSearchQuery] = useState("");

  // Function to handle section filter selection
  const handleFilterSelection = (filter) => {
    setSelectedFilter(filter);
  };

  // Function to handle search query input
  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  useState(() => {
    axios
      .get(`${mainUrl}/store/blogs/`)
      .then((response) => {
        setBlogData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const filteredBlogs = blogData.filter((blog) => {
    // Check if the blog title includes the searchQuery (case-insensitive)
    const titleMatches = blog.title
      .toLowerCase()
      .includes(searchQuery.toLowerCase());

    // If "All" is selected or if the blog's section matches the selectedFilter, include the blog
    const filterMatches =
      selectedFilter === "All" || blog.section === selectedFilter;

    return titleMatches && filterMatches;
  });

  return (
    <div className="bg-gray-100 mx-4 px-4 ">
      <h2 className="text-3xl font-bold text-center mb-2  text-blue-600">
        Latest Blogs
      </h2>
      <h3 className="text-center text-xl font-bold color-blue mb-2">
        Exploring New Things...........
      </h3>

      {/* <div className="flex justify-center mb-4">
        <button
          className={`mx-2 px-4 py-2 rounded ${
            selectedFilter === "All" ? " bg-gray-600" : "bg-white text-blue-600"
          }`}
          onClick={() => handleFilterSelection("All")}
        >
          All
        </button>
        <button
          className={`mx-2 px-4 py-2 rounded ${
            selectedFilter === "Achham"
              ? "bg-gray-600 text-white"
              : "bg-white text-white"
          }`}
          onClick={() => handleFilterSelection("Achham")}
        >
          Discovering Achham
        </button>
        <button
          className={`mx-2 px-4 py-2 rounded ${
            selectedFilter === "Stories"
              ? "bg-gray-600 text-white"
              : "bg-white text-white"
          }`}
          onClick={() => handleFilterSelection("Stories")}
        >
          Our Stories
        </button>
      </div> */}
      <div className="flex justify-center mb-4">
        <input
          type="text"
          placeholder="Search by title..."
          className="px-4 py-2 rounded w-1/3"
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>
      <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {filteredBlogs.map((blog) => (
          <Link
            to={`blogDetail/${blog.name}`}
            state={{
              // date: blog.date,
              date: new Date(blog.created_at).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              }),
              // name: blog.name,
              name: blog.author,
              key: blog.title,
              // imageSrc: blog.imageSrc,
              imageSrc: blog.thumbnail,
              title: blog.title,
              // description: blog.description,
              description: blog.content,
            }}
          >
            <BlogCard
              // date={blog.date}
              date={new Date(blog.created_at).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
              name={blog.author}
              key={blog.title}
              imageSrc={blog.thumbnail}
              title={blog.title}
              description={blog.content}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BlogSection;
