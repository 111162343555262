import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  MDBBtn,
  MDBCheckbox,
  MDBContainer,
  MDBInput,
  MDBTabs,
  MDBTabsContent,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
} from "mdb-react-ui-kit";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import React, { useState } from "react";
import { Cookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  loginUser,
  registerUser,
  removeUser,
} from "../features/user/userSlice";

function LoginSignup() {
  const navigate = useNavigate();

  const {
    id,
    loading,
    first_name,
    last_name,
    username,
    email,
    phone,
    address,
    membership,
  } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const userCookie = new Cookies();

  const [givenEmail, setGivenEmail] = useState("");
  const [givenPassword, setGivenPassword] = useState("");
  const [givenUsername, setGivenUsername] = useState("");
  const [givenName, setGivenName] = useState("");

  const [justifyActive, setJustifyActive] = useState("tab1");

  const handleJustifyClick = (value) => {
    if (value === justifyActive) {
      return;
    }

    setJustifyActive(value);
  };

  const handleRegister = () => {
    const name = givenName.split(" ");

    dispatch(
      registerUser({
        username: givenUsername,
        email: givenEmail,
        password: givenPassword,
        first_name: name[0],
        last_name: name[1],
      })
    );
    handleJustifyClick("tab1");
  };

  const handleLogin = async () => {
    const actionResult = await dispatch(
      loginUser({ email: givenEmail, password: givenPassword })
    );

    if (actionResult && actionResult.payload && actionResult.payload.access) {
      navigate("/");
    }
  };

  if (userCookie.get("access")) {
    return (
      <div className="text-center">
        <button
          className="m-4 w-50"
          onClick={() => {
            dispatch(removeUser());
          }}
        >
          Sign Out
        </button>
      </div>
    );
  }

  return (
    <MDBContainer className="p-3 my-5 d-flex flex-column w-50 ">
      <MDBTabs
        pills
        justify
        className="mb-3 d-flex flex-row justify-content-between"
      >
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleJustifyClick("tab1")}
            active={justifyActive === "tab1"}
          >
            Login
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink
            onClick={() => handleJustifyClick("tab2")}
            active={justifyActive === "tab2"}
          >
            Register
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>

      <MDBTabsContent>
        <MDBTabsPane show={justifyActive === "tab1"}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            action=""
          >
            <MDBInput
              wrapperClass="mb-4"
              label="Email address"
              id="form1"
              type="email"
              value={givenEmail}
              onChange={(e) => setGivenEmail(e.target.value)}
              required
            />
            <MDBInput
              wrapperClass="mb-4"
              label="Password"
              id="form2"
              type="password"
              value={givenPassword}
              onChange={(e) => setGivenPassword(e.target.value)}
              required
            />

            <div className="d-flex justify-content-between mx-4 mb-4 ">
              <Link className="" to="/passwordreset">
                Forgot password?{" "}
              </Link>
            </div>

            {/* <Link to="/home" className="mb-4 w-100">
            <MDBBtn className="mb-4 w-100">Sign in</MDBBtn>
          </Link> */}
            <button className="mb-4 w-100" onClick={handleLogin}>
              Sign in
            </button>
          </form>

          {/* <div className="text-center mb-3">
            <p>Sign in with:</p>

            <div
              className="d-flex justify-content-between mx-auto"
              style={{ width: "4%" }}
            >
              <MDBBtn
                tag="a"
                color="none"
                className="m-1"
                style={{ color: "#1266f1" }}
              >
                <MDBIcon fab icon="google" size="sm" />
              </MDBBtn>
            </div>

            <p className="text-center mt-3">or:</p>
          </div> */}

          <p className="text-center">
            Not a member?{" "}
            <span
              className="cursor-pointer"
              onClick={() => {
                setJustifyActive("tab2");
              }}
            >
              Register
            </span>
          </p>
        </MDBTabsPane>

        {/* here is the register sections */}

        <MDBTabsPane show={justifyActive === "tab2"}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            action=""
          >
            <MDBInput
              wrapperClass="mb-4"
              label="Name"
              id="form1"
              type="text"
              value={givenName}
              onChange={(e) => setGivenName(e.target.value)}
              required
            />
            <MDBInput
              wrapperClass="mb-4"
              label="Username"
              id="form1"
              type="text"
              value={givenUsername}
              onChange={(e) => setGivenUsername(e.target.value)}
              required
            />
            <MDBInput
              wrapperClass="mb-4"
              label="Email"
              id="form1"
              type="email"
              value={givenEmail}
              onChange={(e) => setGivenEmail(e.target.value)}
              required
            />
            <MDBInput
              wrapperClass="mb-4"
              label="Password"
              id="form1"
              type="password"
              value={givenPassword}
              onChange={(e) => setGivenPassword(e.target.value)}
              required
            />

            <div className="d-flex justify-content-center mb-4">
              <MDBCheckbox
                name="flexCheck"
                id="flexCheckDefault"
                label="I have read and agree to the terms"
              />
            </div>

            <MDBBtn className="mb-4 w-100" onClick={handleRegister}>
              Sign up
            </MDBBtn>
          </form>
        </MDBTabsPane>
      </MDBTabsContent>
    </MDBContainer>
  );
}

export default LoginSignup;
