import React from "react";
import { useSelector } from "react-redux";
import Routers from "../Routers/Routers";
import Footer from "./Footer";
import NavBar from "./Header/NavBar";
import TopBar from "./Header/TopBar";
import Modal from "./Modal";

const Layout = () => {
  const { showModal } = useSelector((store) => store.notification);
  return (
    <div className="flex flex-col min-h-screen">
      <TopBar />
      <NavBar />
      {showModal && <Modal />}
      <div className="flex-grow">
        <Routers />
      </div>
      <Footer />
    </div>
  );
};
export default Layout;
