import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mainUrl } from "../../constants";

import { useNavigate } from "react-router-dom";
import { updateNotification } from "../../features/notification/notificationSlice";

const bloodgroupOptions = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];

const BloodDonorForm = () => {
  const { id } = useSelector((store) => store.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [municipalityOptions, setMunicipalityOptions] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [wardOptions, setWardOptions] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [current_address, setCurrent_address] = useState(null);
  const [selectWorkplace, setWorkplace] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    dob: "",
    phoneNumber: "",
    ward: "",
    tole: "",
    profession: "",
    workplace: "",
    province: "",
    district: "",
    municipality: "",
    ward: "",
    current_address: "",
    bloodGroup: "",
    image: null,
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleProvinceChange = (e) => {
    const selectedProvinceId = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      province: selectedProvinceId,
    }));

    axios
      .get(`${mainUrl}/store/districts/?province=${selectedProvinceId}`)
      .then((response) => {
        setDistrictOptions(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDistrictChange = (e) => {
    const selectedDistrictId = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      district: selectedDistrictId,
    }));

    axios
      .get(`${mainUrl}/store/municipalities/?district=${selectedDistrictId}`)
      .then((response) => {
        setMunicipalityOptions(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleMunicipalityChange = (e) => {
    const selectedMunicipalityId = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      municipality: selectedMunicipalityId,
    }));

    axios
      .get(`${mainUrl}/store/wards/?municipality=${selectedMunicipalityId}`)
      .then((response) => {
        setWardOptions(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleWardChange = (e) => {
    const selectedWardId = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      ward: selectedWardId,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      // Form is valid, perform form submission logic here
      console.log("Form data submitted:", formData);

      // formData.append('image', selectedImage);
      formData.user = id;
      formData.image = selectedImage;

      const response = await axios.post(
        `${mainUrl}/store/donor-requests/`,
        {
          ...formData,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        let name = "message";
        let value = "Your request has been submitted successfully";
        dispatch(updateNotification({ name, value }));
        name = "showModal";
        value = true;
        dispatch(updateNotification({ name, value }));
        navigate("/");
      }

      // Reset the form after successful submission

      setFormData({
        name: "",
        dob: "",
        phoneNumber: "",
        ward: "",
        tole: "",
        profession: "",
        workplace: "",
        province: "",
        district: "",
        municipality: "",
        vdc: "",
        current_address: "",
        bloodGroup: "",
      });
      setErrors({});
    } else {
      // Set the validation errors to display to the user
      console.log("error");
      setErrors(validationErrors);
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name.trim()) {
      errors.name = "Name is required";
    } else if (!/^[A-Za-z][A-Za-z\s]*$/.test(formData.name)) {
      errors.name =
        "Name must start with alphabets and can only contain alphabets and spaces.";
    }
    if (!formData.contact.match(/^[0-9]{10}$/)) {
      errors.phoneNumber =
        "Invalid phone number format. Please enter a 10-digit number.";
    }
    if (!formData.dob) {
      errors.dateOfBirth = "Date of Birth is required";
    }
    // Add more validations for other required fields as needed

    return errors;
  };

  useEffect(() => {
    axios
      .get(`${mainUrl}/store/provinces/`)
      .then((response) => {
        setProvinceOptions(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="p-4 mt-4">
            <p className="text-m font-bold text-center mt-0 mb-4 text-black-600">
      Please sign up and log in before filling out this form/कृपया यो फाराम भर्नु अघि साइन अप गर्नुहोस् र लग इन गर्नुहोस्।
      </p>
      <form onSubmit={handleSubmit} className="space-y-4 sm:w-96 mx-auto">
        <div>
          <label htmlFor="name" className="block font-bold">
            Name:
          </label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Please Enter Your Name"
            value={formData.name}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          />
          {errors.name && <p className="text-red-500">{errors.name}</p>}
        </div>

        <div>
          <label htmlFor="contact" className="block font-bold">
            Phone No :
          </label>
          <input
            type="tel"
            id="contact"
            name="contact"
            placeholder="Enter Your Phone number"
            value={formData.contact}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          />
          {errors.phoneNumber && (
            <p className="text-red-500">{errors.phoneNumber}</p>
          )}
        </div>
        <div>
          <label htmlFor="tole" className="block font-bold">
            Tole/village
          </label>
          <input
            type="text"
            id="tole"
            name="tole"
            placeholder="Enter your Tole/Village"
            value={formData.tole}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>

        <div>
          <label htmlFor="dob" className="block font-bold">
            Date of Birth:
          </label>
          <input
            type="date"
            id="dob"
            name="dob"
            value={formData.dob}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>
        <div>
          <label htmlFor="bloodGroup" className="block font-bold">
            BloodGroup :
          </label>
          <select
            id="bloodGroup"
            name="bloodGroup"
            value={formData.bloodGroup}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          >
            <option value=""> Select BloodGroup </option>
            {bloodgroupOptions.map((bloodGroup, idx) => (
              <option key={idx} value={bloodGroup}>
                {bloodGroup}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="profession" className="block font-bold">
            Profession
          </label>
          <input
            type="text"
            id="profession"
            name="profession"
            placeholder="Enter your  profession"
            value={formData.profession}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>
        <div>
          <label htmlFor="workplace" className="block font-bold">
            Workplace
          </label>
          <input
            type="text"
            id="workplace"
            name="workplace"
            placeholder="Enter your  workplace"
            value={formData.workplace}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>

        <div>
          <label htmlFor="province" className="block font-bold">
            Province:
          </label>
          <select
            id="province"
            name="province"
            value={formData.province}
            onChange={handleProvinceChange}
            required
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          >
            <option value="">Select Province </option>
            {provinceOptions.map((province) => (
              <option key={province.id} value={province.id}>
                {province.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="district" className="block font-bold">
            District:
          </label>
          <select
            id="district"
            name="district"
            value={formData.district}
            onChange={handleDistrictChange}
            required
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          >
            <option value=""> Select District </option>
            {districtOptions.map((district) => (
              <option key={district.id} value={district.id}>
                {district.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="municipality" className="block font-bold">
            Municipality/Vdc:
          </label>
          <select
            id="municipality"
            name="municipality"
            value={formData.municipality}
            onChange={handleMunicipalityChange}
            required
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          >
            <option value="">Select Municipality/vdcs</option>
            {municipalityOptions.map((municipality) => (
              <option key={municipality.id} value={municipality.id}>
                {municipality.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="ward" className="block font-bold">
            Ward:
          </label>
          <select
            id="ward"
            name="ward"
            value={formData.ward}
            onChange={handleWardChange}
            required
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          >
            <option value="">Select Ward</option>
            {wardOptions.map((ward) => (
              <option key={ward.id} value={ward.id}>
                {ward.name
                  ? `${ward.name} (${ward.ward_number})`
                  : ward.ward_number}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="current_address" className="block font-bold">
            Current Address
          </label>
          <input
            type="text"
            id="current_address"
            name="current_address"
            placeholder="Enter your current Address (tole/ward-no/Municipality)"
            value={formData.current_address}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>

        <div>
          <label htmlFor="image" className="block font-bold">
            Image:
          </label>
          <input
            type="file"
            id="image"
            name="image"
            accept="image/*"
            // onChange={handleChange}
            onChange={(event) => {
              setSelectedImage(event.target.files[0]);
            }}
            required
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>

        <div>
          <button
            type="submit"
            className="w-full px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default BloodDonorForm;
