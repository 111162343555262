import React, { useState } from "react";

const DonorAndVolunteerDropDownSection = () => {
  const [
    selectedAchhamBloodDonorAndVolunteer,
    setSelectedAchhamBloodDonorAndVolunteer,
  ] = useState("");

  const [openSection, setOpenSection] = useState(null);

  const handledonorvolunteerChange = (option) => {
    setSelectedAchhamBloodDonorAndVolunteer(option);
  };

  const Dropdown = ({ title, options, selectedOption, onSelect }) => {
    const toggleDropdown = () => {
      setOpenSection(openSection === title ? null : title);
    };

    const handleOptionSelect = (option) => {
      onSelect(option);
      setOpenSection(null);
    };

    return (
      <div className="w-full md:w-64 dropdown-container mt-10">
        <label htmlFor={title.toLowerCase()} className="block font-bold mb-2">
          {title}:
        </label>
        <div className=" relative">
          <div
            onClick={toggleDropdown}
            className="block  appearance-none w-full border border-gray-300 rounded px-4 py-2 focus:outline-none cursor-pointer"
          >
            {selectedOption ? (
              <a
                href={selectedOption.link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 "
              >
                {selectedOption.name}
              </a>
            ) : (
              `Select ${title}`
            )}
            <span className="ml-1">&#9660;</span>
          </div>
          {openSection === title && (
            <ul className="dropdown-content bg-gray-100 border border-gray-300 rounded mt-1 py-1 w-full">
              {options.map((option, index) => (
                <li
                  key={index}
                  onClick={() => handleOptionSelect(option)}
                  className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                >
                  <a
                    href={option.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600"
                  >
                    {option.name}
                  </a>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col md:flex-row justify-center  gap-4 py-8 mx-4">
      <Dropdown
        title="Apply as Blood Donor/Volunteer"
        options={[
          {
            name: "BLOOD DONOR MEMBER",
            link: "/apply-achhamblooddonor",
          },
          {
            name: "APPLY VOLUNTEER",
            link: "/apply-volunteer",
          },
        ]}
        selectedOption={{
          name: selectedAchhamBloodDonorAndVolunteer,
          link: null,
        }}
        onSelect={handledonorvolunteerChange}
      />
    </div>
  );
};

export default DonorAndVolunteerDropDownSection;
