import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
const AboutSection = () => {
  return (
    <div className="w-full  bg-gray-100">
      <div className=" max-w-[1240px] mx-auto grid md:grid-cols-2">
        <img
          className="w-[auto] h-[auto] mx-auto my-4 rounded-lg"
          src="https://th.bing.com/th/id/OIP.6Q4HuXyY3XNt51DdzI2fmgHaEK?pid=ImgDet&rs=1"
          alt="Images Looding Soon"
        />
        <div className="flex flex-col justify-center md:items-start w-full px-2">
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold mb-2 md:items-start w-full  text-blue-600">
            Message From Lifeline Achham
          </h1>
          <p className="flex flex-col justify-center md:items-start w-full px-2">
            Today, we honor the LifeLine Achham community, a group dedicated to tirelessly working every day and making personal sacrifices to uplift those in need. Our project serves as a beacon of hope, providing vital information to those who require blood, regardless of type. This initiative is a step towards ensuring that every young person in the rural areas of Achham District receives timely medical care, bringing healthcare closer to home and strengthening our community

          </p>
          <p className=" font-bold text-2xl  text-blue-600">Ganesh Kunwar</p>
          <p>Founder/ Software Developer</p>
          <div className="flex justify-start">
            {/* <a
              href="https://www.facebook.com/ganesh.kunwar.710"
              target="_blank"
            >
              {" "}
              <FaFacebookF className="mx-2 " />
            </a> */}

            <a href="https://www.facebook.com/ganesh.kunwar.710">
              <FaFacebookF className="mx-2 " />
            </a>
            <a>
              <FaTwitter className="mx-2" />
            </a>
            <a>
              <FaInstagram className="mx-2" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
