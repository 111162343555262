import axios from "axios";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
// import quotes from "../../assets/images/01.png";
import { mainUrl } from "../constants";

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    axios
      .get(`${mainUrl}/store/reviews`)
      .then((response) => {
        setTestimonials(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const settings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: false,
    speed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className=" bg-gray-100 mb-5 p-auto mx-auto">
      <h2 className="text-3xl font-bold text-center mt-8 text-blue-600">
        Real People, Real Stories: What Our BloodDonor's Say
      </h2>
      <Slider className="mx-4" {...settings}>
        {testimonials.map((item, index) => (
          <div
            className="testimonial__item mx-3 mt-4   p-4 rounded "
            key={index}
          >
            <div className="p-5 sm:p-0 bg-white ">
              <p className="pb-4">{item.review.slice(0, 250) + "  ..."}</p>
              <div className="flex items-center gap-4">
                <img
                  src={`${item.image}`}
                  alt={item.name}
                  className="w-20 h-20 rounded-full"
                />
                <div>
                  <h5 className="text-xl font-semibold">{item.name}</h5>
                  <span>{item.profession}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Testimonials;
