import React from "react";
import BlogSection from "../components/BlogSection/BlogSection";

const Blogs = () => {
  return (
    <div className="bg-gray-100 mt-2 mb-4 ">
      <div className="bg-gray-100">
        <BlogSection />
      </div>
    </div>
  );
};

export default Blogs;
