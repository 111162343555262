import React from "react";

const FAQ = () => {
  return (
    <>
      <h1 className="text-center font-extrabold mt-4">FAQ</h1>
      <p className="text-center ">
        Updating very soon ............................
      </p>
    </>
  );
};

export default FAQ;
