import React from "react";
import umeshImage from '../assets/images/umesh-kunwar.jpg';
import ganeshImage from '../assets/images/Ganesh-kunwar.jpg';
import mahendraImage from '../assets/images/mahendra-kunwar.jpg';
import krishnaImage from '../assets/images/krishna-chand-sodari.jpg';
import mandipImage from '../assets/images/dr-mandip-pathak.jpg';
const TeamMember = () => {
  const profileData = [
    {
      name: "Ganesh  Kunwar",
      position: " Founder /Software Developer",
      Number: " 9810228669",

      imageSrc:ganeshImage
    },
    {
      name: "Dr. Krishna Bahadur Sodari",
      position: " MBBS/Achham District Hospital",
      Number: " 9843757325",

      imageSrc:krishnaImage
    },
    {
      name: "Mahendra Kunwar",
      position: "Anesthesia Officer /Bayalapata Hospital",
      Number: " 9848444014",

      imageSrc:mahendraImage
    },
    {
      name: "Dr. Mandip Pathak",
      position: " Medical Surgical Director /Orthopaedic Surgeon /Bayalapata Hospital",
      Number: " 9851273599",

      imageSrc:mandipImage
    },
    {
      name: "Umesh Kunwar",
      position: " Social Worker/ Youth Leader",
      Number: " 9865780071",
      imageSrc:umeshImage
    }
  ];
  const volunteerData = [
    {
      sn: 1,
      name: "Ganesh Kunwar",
      address: "Sanfebagar",
      number: "9810228669",
    },
    {
      sn: 2,
      name: "Arbin Kunwar",
      address: "Sanfebagar,Airport Bazar",
      number: "9742387566",
    },
    {
      sn: 3,
      name: "Mahendra Kunwar",
      address: "Sanfebagar,Bayalapata Hospital",
      number: "9848444014 ",
    },
    {
      sn: 4,
      name: "Top Bahadur Bista",
      address: "Mangalsen ,Achham District Hospital",
      number: "9848435249",
    },
    {
      sn: 5,
      name: "Yakendra Kunwar ",
      address: "Bayalapata Hospital",
      number: "9812697565",
    },
    {
      sn: 6,
      name: "Dr Krishna Chand Sodari ",
      address: "Mangalsen ,Achham District Hospital",
      number: "9843757325",
    },
    {
      sn: 7,
      name: "Umesh Kunwar ",
      address: "Sanfebagar ,Airport Bazar",
      number: "9858486395/9865780071",
    },


    // Add more volunteers here if needed
  ];


  return (
    <div>
      <h1 className="text-center text-3xl font-bold   text-blue-600 bg-gray-100 ">
        Meet Our Team
      </h1>
      <div className="grid  grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 mt-4 mb- mx-8 bg-gray-100 ">
        {profileData.map((profile, index) => (
          <div
            key={index}
            className="w-full h-full max-w-xs mx-auto bg-white rounded-lg shadow-lg overflow-hidden"
          >
            <img
              className=" w-full h-40"
              src={profile.imageSrc}
              alt={profile.name}
            />
            <div className="py-4 px-6">
              <h2 className="text-2xl font-bold text-gray-800">
                {profile.name}
              </h2>
              <p className="text-sm text-gray-600">{profile.position}</p>
              <p className="text-sm text-gray-600">Contact Number:{profile.Number}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="container mx-auto px-4 py-6">
        <h1 className="text-center text-2xl font-bold text-blue-600 bg-gray-100 py-2">
        Volunteer Team
        </h1>

        <div className="overflow-x-auto mt-6">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="px-6 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-sm leading-4 text-gray-600 uppercase tracking-wider">SN</th>
                <th className="px-6 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-sm leading-4 text-gray-600 uppercase tracking-wider">Name</th>
                <th className="px-6 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-sm leading-4 text-gray-600 uppercase tracking-wider">Address/Municipality/VDCs</th>
                <th className="px-6 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-sm leading-4 text-gray-600 uppercase tracking-wider">Contact Number</th>
              </tr>
            </thead>
            <tbody>
              {volunteerData.map((volunteer) => (
                <tr key={volunteer.sn}>
                  <td className="px-6 py-4 border-b border-gray-200">{volunteer.sn}</td>
                  <td className="px-6 py-4 border-b border-gray-200">{volunteer.name}</td>
                  <td className="px-6 py-4 border-b border-gray-200">{volunteer.address}</td>
                  <td className="px-6 py-4 border-b border-gray-200">{volunteer.number}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>

    // </div >
  );
};

export default TeamMember;

