import React from "react";

import CounterSection from "../components/CounterSection";
import HeroSection from "../components/HeroSection";
import ImageSection from "../components/ImageSection";
import Facilities from "../components/Services";

import SponsoredSection from "../components/SponseredSection";
import Testimonials from "../components/Testimonials";
import VideoSection from "../components/YoutubeVideos";

const Home = () => {
  return (
    <div className="bg-gray-100 min-h-screen">
      <div className=" mx-auto px-4 ">
        <HeroSection />
        <div>
          <CounterSection />
          <Facilities />
          <ImageSection />
          <VideoSection />
          <Testimonials />
          <SponsoredSection />
        </div>
      </div>
    </div>
  );
};

export default Home;
