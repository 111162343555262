import React from "react";
import { Link } from "react-router-dom";
import GalleryDetailPage from "./GalleryDetailPage";
const GalleryCard = ({ date, imageSrc, title, gallery }) => {
  const showGalleryImages = () => {
    return <GalleryDetailPage imagess={gallery.images} />;
  };

  return (
    <div
      className="mb-2  relative max-w-sm rounded overflow-hidden shadow-lg bg-slate-400"
      onClick={showGalleryImages}
    >
      {/* <Link to="#"> */}
      <Link to={`/gallery-images/${gallery.id}`}>
        <img
          className="w-full h-48 object-cover cursor-pointer transform transition-transform duration-300 hover:scale-105"
          src={imageSrc}
          alt="Blog post"
        />
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-0 transition-opacity duration-300 hover:opacity-50" />
        <div className="absolute bottom-0 left-0 w-full bg-black text-white text-center py-2">
          <span className="text-gray-300 text-sm font-bold">{date}</span>
          <h3 className="text-xl font-bold">{title}</h3>
        </div>
      </Link>
    </div>
  );
};

export default GalleryCard;
