import React from "react";
import AboutSection from "../components/AboutSection";
import TeamMember from "../components/TeamMember";

const AboutUs = () => {
  return (
    <div className="bg-gray-100">
      <AboutSection />
      <div className=" mt-4 mb-4 bg-gray-100">
        <TeamMember />
        {/* <PasswordResetPage /> */}
      </div>
    </div>
  );
};

export default AboutUs;
