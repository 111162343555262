import React, { useState } from "react";
import { Link } from "react-router-dom";

const BlogCard = ({ date, name, imageSrc, title, description }) => {
  const [showContent, setShowContent] = useState(false);

  const toggleContent = () => {
    setShowContent(!showContent);
  };

  return (
    <div className="max-w-sm rounded overflow-hidden shadow-lg ">
      <Link to="#">
        <img
          className="w-full h-48 object-cover cursor-pointer  transition-transform duration-300 hover:scale-105"
          src={imageSrc}
          alt="Blog post"
        />
      </Link>
      <div className="px-6 py-2">
        <span className="text-blue-700 text-base font-bold   ">
          {name} || {date}
        </span>
      </div>
      <hr />
      <div className="px-6">
        <div className="font-bold text-xl mb-2">{title}</div>
        {/* Show the description based on showContent state */}
        <p className="text-gray-700 text-base overflow-hidden h-12 line-clamp-3">
          {description.length > 40
            ? `${description.substring(0, 40)}...`
            : description}
        </p>
      </div>
    </div>
  );
};

export default BlogCard;
