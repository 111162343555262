import axios from "axios";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { mainUrl } from "../constants";

const ImageSection = () => {
  const [images, setImages] = useState([]);
  const setting = {
    slidesToShow: 5,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    infinity: true,
    autoplay: true,
    speed: 1000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  useEffect(() => {
    axios
      .get(`${mainUrl}/store/galleries/recent/`)
      .then((response) => {
        setImages(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="bg-gray-100 w-full py-8 ">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-4  text-blue-600">
          Image Gallery
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 ">
          <Slider className="grid sm:grid-cols gap-9 " {...setting}>
            {images.map((image, index) => (
              <div key={index} className="border rounded-lg overflow-hidden">
                <div style={{ paddingBottom: "100%", position: "relative" }}>
                  <img
                    src={`${mainUrl}${image.image}`}
                    // src={image}
                    alt={`Image ${index + 1}`}
                    className="absolute top-0  left-0 w-full h-full object-cover"
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default ImageSection;
