import React from "react";

const FuturePRojectlifelineachham = () => {
  return (
    <>
      <h1 className="text-center font-extrabold mt-4"> Projects</h1>
      <p className="text-center">
        Adding project very soon of lifeline achham..........................
      </p>
    </>
  );
};

export default FuturePRojectlifelineachham;
